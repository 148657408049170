import { useAppDispatch } from "@/shared/redux/hooks";
import { CardMessage } from "@/shared/types/types";
import {
  addToCart,
  changeQuantity,
  removeFromCart,
  removeInCart,
  setRemovedInCart,
} from "@/shared/redux/features/userSlice";

export const useCartActions = () => {
  const dispatch = useAppDispatch();

  const add = (card: CardMessage) => {
    return dispatch(
      addToCart({
        id: Number(card.id),
        quantity: card.quantity,
        price: card.price,
      })
    );
  };

  const remove = (card: any) => {
    const data = {
      id: card.product_id,
      quantity: card.quantity,
      price: card.price,
    };
    dispatch(setRemovedInCart({ id: card.id, removed: true }));
    dispatch(removeFromCart(data));
    return dispatch(removeInCart(card.id));
  };

  const change = (card: CardMessage) => {
    return dispatch(
      changeQuantity({
        id: card.id,
        quantity: card.quantity,
        clear: card.clear,
      })
    );
  };

  return { add, remove, change };
};
