import { useEffect } from "react";
import { usePathname, useSearchParams } from "next/navigation";

interface IUseMetrikaHit {
  dependence: string[] | number[] | boolean[];
}

const useMetrikaHit = (props: IUseMetrikaHit) => {
  const { dependence } = props;
  const pathName = usePathname();
  const searchParams = useSearchParams();
  useEffect(() => {
    // ym(42989679, "hit", window.location.href);
  }, dependence);
  return;
};

export default useMetrikaHit;
