import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@/shared/redux/hooks";
import {
  clearCartOnRefresh,
  deleteProductsArray,
  getAuthStatus,
  getCart,
  isRemovedProducts,
} from "@/shared/redux/features/userSlice";
import { usePathname } from "next/navigation";

export const useCheckRemovedProducts = () => {
  const cart = useAppSelector(getCart);
  const pathname = usePathname();
  const dispatch = useAppDispatch();
  const isAuth = useAppSelector(getAuthStatus).isAuth;
  const removedProductsStatus = useAppSelector(isRemovedProducts);
  useEffect(() => {
    const ids = cart.filter((product) => product.isRemoved).map((product) => product.id || product.product_id);
    const removedProducts = cart.filter((product) => product.isRemoved);
    if (pathname !== "/cart/" && ids.length > 0) {
      if (removedProductsStatus) return;
      if (isAuth) {
        dispatch(deleteProductsArray({ ids, products: removedProducts }));
      } else {
        dispatch(clearCartOnRefresh());
      }
    }
  }, [cart, pathname, dispatch, isAuth, removedProductsStatus]);
};
